$jet         : #999;
$black       : #000;
$white       : #FFF;
$charcoal	 : #222222;
$steal     : #797979;
$orange		 : #ff9324;
$orange1	 : #eb6e37;
$orange2	 : #f0530e;
$scblue      : #485468;
$sierra		 : #a45415;
$primary-color: #999;
$maven-color: #92137b;
$maven: #831170;

$images		 :/images;


$body-font-family:'Maven Pro', Roboto, Arial, sans-serif;
$header-font-family: 'Raleway', Roboto, Arial, sans-serif;


$body-font-color: $jet;

$foundation-palette: (
  primary: #999,
  secondary: #485468,
  success: #3adb76,
  warning: #ffae00,
  alert: #ec5840,
);

$menu-item-background-active: transparent;
$menu-item-padding: 0rem;
$menu-margin-nested: 0rem;
$menu-icon-spacing: 0rem;


$topbar-padding: 0.5rem;
$topbar-background: $white;
$topbar-submenu-background: $topbar-background;
$topbar-title-spacing: 1rem;
$topbar-input-width: 200px;
$topbar-unstack-breakpoint: medium;
