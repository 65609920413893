body{
  margin:0;
  padding:0;
  width: 100%;
  text-align: left;
}

h1{
  font-size: 2.5rem;
  font-weight: 600;
}

h2{
  font-size: 1.0rem;
  font-weight: 500;
}

h3{
  font-size: .7rem;
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6{
color: $scblue;
}



smallcubed {
  padding:0;
  margin-right:auto;
  margin-left: auto;
  width:90%;
  margin-top: 200px; 
}

div.demo_header {
  position:absolute;
  top:200px;
  left:300px;
  padding-left: 20px;
  min-width: 50% !important;
  background-color: red;
  opacity: 0.5;
  -ms-transform: skew(170deg,170deg);
  -webkit-transform: skew(170deg,170deg);
  transform: skew(170deg,170deg);
  
  h1{
    font-size: 10rem;
    font-weight: bolder;
    color:#800000;
  }
}


.site-sidebar {
  border-left:1px solid #999;
  padding-left: 20px;
  font-size: 2rem;
}


#page_footer{
  margin:0;
  padding:0;
  width: 100%;
  z-index: 999;

#footer {
  padding:0px;
  margin:0;
  width: 100%;
  background: $scblue;
  color: #999;
  border: none;

  .footer-logo-inverted {
    padding-top: 0px;
    margin-top: 5px;
    margin-left: 5px;
    padding-bottom: 0px;
    width: 175px;
    }

  li {
    color: #999;
  }
}

  #sub_footer {
    padding-top:10px;
    padding-bottom: 10px;
    margin:0;
    width: 100%;
    min-height: 100px;
    background: $light-gray;
    border: none;
    color: $scblue;
    font-size: 1.2rem;
    text-align:center;

    h3 {
      margin-left: 0;
      font-size: 1.2rem;
      line-height: 1.1rem;
      font-weight: bolder;
    }
    
    a:link {
      color: $scblue;
    }

    a:visited {
      color: $scblue;
    }

    a:hover {
      color: $scblue;
      font-weight: 600;
      letter-spacing: -0.06em;
    }
    a:active {
      color: #384155;
    }
    li {
      list-style-type: none; 
      color:$scblue; 
    }

    @media screen and (min-width: 45rem) {
      text-align:left;
      .center-column{
      margin-left: 10%;
      }
      .right-column{
      margin-left: 30%;
      }
    }
  }
}

.banner{
  background-image: url("https://s3.amazonaws.com/media.smallcubed.com/images/scs/big_sur.png");
  float: left; 
  margin-top: -70px; 
  margin-left:45%; 
  margin-right:auto; 
  border: solid 2px #a45415; 
  border-radius:10%; 
  width:150px; 
  padding:5px; 
  opacity:0.7;
  
  
  .banner-content{
    width: 380px;
    border: solid 1px #eb6e37;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
  }
    
    .banner-text {
      color: $jet;
      padding:10px;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: normal;
    }  

  }


.other_plugins {
  li img, a img, img {
    width: 68%;
  }
}

#requirements_notes{
  padding-top: -30px;
  margin: 0px;
  padding-left: 2.3em;
  padding-right: 2.3em;
    
}
  
  
#scs_release {
  min-height: 200px;
  width: 100%;
  margin-top:20px;
  font-size: 1.5rem;

.zenapp{
  font-size: 1em;
}  

p{
  font-size: 1rem;
}

  p.release-date {
  margin-left:1.2em;
  top: -100px;
  }

  .release-download{
     width: 150px;
      margin-left:auto;
      margin-right:auto;
      border:5px solid #005392;
      background: #005392;
      border-radius: 5px;
      text-align: center;
      color: #FBD54F;
      font-weight: 900;
      font-size: 1.4rem;
      text-shadow: 0.8px 0.8px ;
      
  }

  @media screen and (min-width: 45rem) {
    margin-top:80px;

    #downloads{
      margin-top:-150px;
    }

    a.download{
      width: 10em;
      margin-bottom: 1.2em;
      
      padding: 5px 20px 5px 20px;
      background-color: $scblue;
      border:2px solid $scblue;
      border-radius: 5px;
      text-align: center;
      color: #FBD54F!important;
      font-weight: 900;
      font-size: 1.8rem;
      text-shadow: 0.8px 0.8px ;

    }   

    a.download:hover{
    background-color: #5A6982;
    color:#FBD54F!important;
    border: solid 2px #5A6982;
  } 
  a.download:visited{
    color:#FBD54F;
  } 

  }

  a:link {
    color: #000066;
  }

  a:visited {
    color: #000066;
  }

  a:hover {
    color: #000066;
  }

  a:active {
    color: #000066;
  }

  .change_type.new {
    color: green;
    font-weight: 800;
  }
  .change_type.fix {
    color: #a71a28;
    font-weight: 800;
  }
  .change_type.os {
    color: #5672d8;
    font-weight: 900;
  }

  .new_features div {
    padding-left: 1em;
    color: #565656;
    font-weight: 800;

    li{
      font-size:1.2;
    }
  }

  .release-container{
    .release-notes{
      margin: 0px;
      padding-left: 1.5em;
      padding-right: 1.5em;
    
      li{
        list-style: none;
        font-size: 1.2rem;
      }
    
      .release-title{
        font-size: 1.6rem;
      }
    }
  }


}



#about_us {
  min-height: 800px;
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;

  a:link {
    color: $scblue;
  }

  a:visited {
    color: $scblue;
  }

   a:hover {
    color: $scblue;
    font-weight: 600;
    letter-spacing: -0.06em;
  }
  
  a:active {
    color: #384155;
  }

  .about_header {
    
    margin-top:130px;
    
     h1{
      color: #000;
      line-height: 100%;
      text-indent: -1em; 
      margin-left: 1em;
    }
  }

  .about-header-icon {
    margin-top: -40px;
      
    img, li img, a img,{
        width: 65%;
    }
  }

  .about-container{
   background: #f5f5f5;
  }
  
  .about-title{
        font-size: 1.1rem;
    }

  .about-us{
    .img-circle {
      border: 5px solid #333333;
      border-radius: 50%;
      width: 150px;
      box-shadow: 0 6px 4px rgba(0, 0, 0, 0.3);  
    }
  } 
}

//***************Support************

.support-header {
  margin-top:100px;
    
  h1{
    font-size: 3.5rem;
    line-height: 100%;
    text-align: left;
  }
}


.support-sidebar{ 
    margin-top: 50px;
    width:330px;
    font-size: .4rem;
    margin-left: 0;
    padding-bottom: 10px;

  h1{ 
    font-size:2rem;
    font-weight: 900;

  }

  h2{
    font-size:1.7rem;
    font-weight: 700;

  }

  h3{
    font-size:1.5rem;
    font-weight: 500;

  }

  sign-in {
    margin-left: 5px;
    width:80%;
    border: 1px solid $scblue;
    color: $white;
    strong{
      color:white;
    }
  }

  button {
    margin-left: 5px;
    width:100%;
    background-color: $scblue;
    color: $white;
    strong{
      color:white;
    }
  }

  li {
    color: $charcoal;
    }

  @media screen and (min-width: 45rem) {
    margin-top: 80px;
  }  
}

  
.toc{
  background-color: #fefefe;
  border-left: solid 1px $scblue;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-size: 0.9rem;

  p{
    font-size: 1.1rem;
  }

h1{
  font-size: 2rem;
}

h2{
  font-size: 1.25rem;
}

h3{
  font-size: 1.2rem;
}

  hr{
   color: $scblue;
   margin-right: 10px;
  }


  a {
     color: $scblue;
  }

  a:hover {
      color: $charcoal;
  }


  }

.toc_print{
  background-color: #fefefe;
  padding-top: 10px;
  padding-bottom: 10px;


   @media screen and (min-width: 45rem) {
      margin-left: 100px;

   }
  
  hr{
   color: $scblue;
   margin-right: 10px;
  }

  a {
     color: $scblue;
  }

  a:hover {
      color: #999;
  }

}

.support-subscribe {
  margin-top: 40px;
  color:$charcoal; 
  border: 1px solid #101831;
  border-radius: 10px;
  background-size: 100%;
  width: 80%;
  padding: 5px;
  font-size: 1rem;
  text-align:center;
  opacity: 0.8;
  margin-right:auto;
  margin-left:auto;

  h1{
    color:$charcoal; 
    text-align:center;
    text-shadow: 1px 1px 1px $charcoal;
  }
  
  h2{
    font-size: 1.6rem;
    color:$charcoal; 
    text-align:center;
    font-weight: 700;
    text-shadow: 1px 1px 1px $charcoal;
  }

  p{
    color:$charcoal; 
    font-size: 1.2rem;
    text-shadow: 1px 1px 1px $charcoal;
  }

  .form_old{
    width: 100%;
    margin-top: 0.25em;
    margin-bottom: 2em;
    text-align: none;


    label {
    color:#fefefe; 
    font-weight: 700;
    font-size: 1.3rem;

    }


  ::placeholder{
      color:  #fefefe;
    }

    input[type="text"] {
      background-color: #8289ba;
      padding: 5px;
      width: 100%;
      border: solid 1px #fefefe;
      font-weight: 700;
      color:  #fefefe;
    }

    input[type="submit"], input[type="button"] {
      float:right;
      
      font-weight: 700;
      font-size: 1rem;
      border-color: transparent;
      background-color: #8289ba;
      text-align: center;
      color:  #fefefe;

    }
  }


form{
  width:100%; 
  padding:30px;

  input[type="text"], input[type="email"] {
    width: 70%;
    margin-bottom: 25px;
    border:none;
    box-shadow: none;
    border-bottom: solid 1px $scblue; 
  }

   .button {
          width:40%;
          margin-top:20px;
          margin-right: auto;
          margin-left: auto;
          font-family: $header-font-family;
          font-size: 1.4rem;
          font-weight:900;
          background-color: $scblue;
          border: solid 1px $scblue;
          border-radius: 10px;
        }
}

}


.support-content{
    font-size: 1.1rem;


     @media screen and (min-width: 45rem) {
      font-size: 1.4rem;

    }

    h1{
      font-size: 2.8rem;
      font-weight: 900;
    }

    h2{
      font-size:2.2rem;
      font-weight: 700;
    }

    h3{
      font-size:1.8rem;
      font-weight: 700;
    }


    h4{
      font-size:1.5rem;
      font-weight: 500;
    }

    img {
      border: 2px solid #485468;
      margin-top: 10px;
      margin-bottom: 15px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .icon-img{
    img {
      width: 100px;
      float: left;
    }
  }
}

  .beta-notes{
    font-size: 1.1rem;


    a {
      color:$scblue;
    }

    a:hover{
      font-weight: 900;
    }

  }



.support_links{
  padding-right: 10px;
  line-height: 1.4;
  font-size: 1.3rem;
  color: #FBD54F;
}


.older-download{
  float: right;
  width: 110px;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  border:5px solid #005392;
  background: #005392;
  border-radius: 5px;
  text-align: center;
  color: #FBD54F;
  font-weight: 900;
  font-size: 4rem;

  a:link {
    color: #FBD54F;
    font-weight: 900;
  }

  a:active {
    color: #FBD54F;
    font-weight: 900;
  }

  a:hover {
    color: #FBD54F;
    font-weight: 900;
  }

  a:visited {
    color: #FBD54F;
    font-weight: 900;
  }
}

.support-download{
  width: 100%;


download{
      width: 10em;
      margin-left:auto;
      margin-right:auto;
      margin-bottom: 1.2em;
      padding: 5px 20px 5px 20px;
      border:2px solid $scblue;
      background: $scblue;
      border-radius: 5px;
      text-align: center;
      color: #FBD54F;
      font-weight: 900;
      font-size: 1.8rem;
      text-shadow: 0.8px 0.8px ;

    }   

download:hover{
    background-color: #5A6982;
    color:#FBD54F;
    border: solid 2px #5A6982;
  } 
  a{
    color:#FBD54F;
  } 
  a:hover{
    background-color: #5A6982;
    color:#FBD54F;
    border: solid 2px #5A6982;
  }  

}


.article{

  h2 {
      margin: 10px 0 10px;
      font-size: 2.2rem;
      line-height: 1.6;
      font-weight: 700;
      font-style: normal;
      text-decoration: none;
      border-bottom: 3px solid $scblue;
    }
}


.print {
  font-size: 1.1rem;
  

  img{
    width: 100%;
    } 
}

.manual{

  h2 {
        margin: 10px 0 10px;
        font-size: 2.2rem;
        line-height: 2.1em;
        font-weight: 800;
        font-style: normal;
        text-decoration: none;
        border-bottom: 3px solid $scblue;
      }
}

.comp_release{
  font-size: 1.2rem;
}

.comp-release-links {

  a {
    color: #999;
    font-weight: 700;
    }

    a:hover {
    color: $scblue;
    }
}


//strong {
//  color:$scblue;
//  font-weight:400;
//}
  
label{
  color:$scblue;
  font-weight: 800;
  font-size: .8rem;
}

p.support-date {
  margin-left:1.2em;
  top: -100px;
}

.support-banner{
  margin-top: -20%;
  width:150px;
  height:150px;
  float:right;

  background-image: url('https://s3.amazonaws.com/media.smallcubed.com/images/scs/venturaLogo.jpeg');
  background-repeat: no-repeat;
  border: solid 2px #C34729; 
  border-radius:10%; 
  padding:5px; 
  opacity:0.7;
  
  
  .banner-content{
    color:#222; 
    text-align:center; 
    font-size:1.5rem; 
    font-weight:900; 
    font-family: 'Francois One', sans-serif;

  }

    
  .banner-text {
    color: #eb6e37;
    font-size: 2rem;
    font-weight: 800;
    line-height: 100%;
    letter-spacing: normal;
    text-align: center;
    text-shadow: 1px 1px 5px #485468;
  }  


  @media screen and (min-width: 45rem) {
    .support-banner{
      margin-top: -10%;
      margin-right: 10%;

      .banner-text {
      }

    }   
  }
}

.presskit{
  font-size: 1.1rem;

}

.token{
    background-color: #65AADC;
    padding-top:2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border: solid 1px #fff;
    border-radius: 7%;

    color: #fff;
    font-weight: 700;
  }

//***************Support End*********

.mojave{
  margin: 0 10% 10%;
  color:#fefefe; 
  border: 1px solid #101831;

  background-image: url("https://s3.amazonaws.com/media.smallcubed.com/images/MojaveBackground_night@2x.png");
  padding: 8%;

  h1{
      color:#fefefe; 
      text-align:center;
      font-size: 2.5rem;
    }
    
    h2{
      color:#fefefe; 
      text-align:center;
      font-weight: 700;
    }

  ol{
    li{
    color:#fefefe;
    }
  }

    a: {
      color: #b2b2b2;
      font-weight: 700;
      }

      a:visited {
      color: #b2b2b2;
      font-weight: 700;
      }

      a:hover {
      color: #b2b2b2; 
      font-weight: 800;
      }

  }


#promo{
  min-height:900px;
  text-align: center;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  
  .header {
    img{
      margin-top: 60px;
      width: 250px;
      margin-bottom: 30px;
    }
  }

  h1{
    margin-top:30px;
  }

  p {
    color: $charcoal;
    font-size: 1.2rem;

  }
  img{
    margin-top: -35px;
  }

  button{
    width:150px;
  }
}
//***************************************************************************
.upgrade{
  left: 0;
  width: 100%;
  
  
  .upgrade-content{
    width: 600px;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
  }
    
    .upgrade-text {
      color: $scblue;
      padding:20px;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: normal;
    }  


     @media screen and (min-width: 45rem) {

     }   
  }


//****************************************************************************

#sparkle_notes {
  margin-left: 1em;
  margin-right: 1em;
  text-align: centered;
  font-size: 1.2rem;
}

h3.second {
  margin-top: 0.75em;
  padding-top: 0.5em;
  border-top: 1pt #959595 dotted;
}


.beta_release p {
  color:red;
}

/* Release formatting */


.no-padding{
  padding:0;
  margin: 0;
  border: 0;
}


.white{
  background: $white;
}

.spacing {
  height: 20px;
}

.spacing.two {
  height: 40px;
}

.spacing.three {
  height: 60px;
}

.spacing.four {
  height: 80px;
}

.spacing.five {
  height: 100px;
}

.shift-down5 {
    display:block; 
    margin-top:5%;
  }

.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }


.product-name{
 font-weight: 900;
 color: $scblue;
}

.infusionsoft{
  font-style: italic;  
 font-weight: 600;
 color: $scblue;
}

.warn {
  font-size: .8rem;
  font-weight: 900;
  color: $scblue;

}

.aside {
  color: $scblue;
}

.holder-400 {
  width: 400px;
  height: 250px;
  border: 1px solid #e6e6e6;
  font-size: 0.8rem;
}

#store {
  min-height: 1000px;
  width: 100%;
}

.text-nowrap {
  white-space: nowrap;

}

.text-left {
  text-align: left;
}

.text-right
{
  text-align: right;
}

.text-center{
  text-align: center;
}

.center {
    width: 290px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
}

.outline{
  border: 4px solid $orange;
}

.outline_rd{
  border: 2px solid red;
}

.outline_bl{
  border: 2px solid $scblue;
}

.outline_bl_lt{
  border-left: 2px solid $scblue;
}

.outline_bl_rt{
  border-right: 2px solid $scblue;
}

.highlight.orange{
    color: $orange;
    font-weight: 800;
}

.highlight.red {
    color: #a71a28;
    font-weight: 800;
    line-height: 0.8;
    }

 .highlight.red.normal {
    color: #a71a28;
    font-weight: 800;
  }

.highlight.green {
    color: green;
    font-weight: 800;
    }

.highlight.blue {
    color: #5672d8;
    font-weight: 800;
    }

.floatright {
    float: right;
}

.floatleft {
    float: left;
}

.mailmaven{
  color: $charcoal;
  font-size: .8em;

  h2 {
    color: $charcoal;
    font-size: 1.8em;
  }

  a:link {
        color: $scblue;
      }

      a:visited {
        color: $scblue;
      }

      a:hover, li a:hover {
        color: $charcoal;
        font-weight: 600;
      }

      a:active {
        color: $scblue;
      }
   
   .mm {
    color: $maven-color;
    font-weight: bolder;
      

      a:link {
        color: $maven-color;
      }

      a:visited {
        color: $maven-color;
      }

      a:hover, li a:hover {
        color: $charcoal;
        font-weight: 600;
      }

      a:active {
        color: $maven-color;
      }

    }

    
}

.mailmaven.downloads{

      h2 {
        font-size: 1.3em;
      }

    }

.mailmaven.home {
  font-size: 1.2em;

    }

.sonoma {
    margin-top: 50px;
    line-height:30px;
    font-size: 1.2em;
    color: #5E5E5E;

    h1, h2 {
      color: #485468;
                font-weight: 900;
    }
    

}

.sonoma.home{
  font-size: 0.8em;

}

//****************************************************************************
#user_section {

  text-align: center;
  margin-bottom: 5.0em;
  margin-top: 5.0em;
  min-height: 480px;
  width: 100%;

  form {
    margin-top: 1.0em;
    margin-bottom: 1.5em;
    
    p {
      margin-top: 0.5em;
      margin-bottom: 0.25em;
    }
    input[type="submit"], input[type="button"] {
      margin-top: 0.75em;
    }
    button{
      margin-top: 0.5em;
      padding:0.5em;
      border: solid 2px $scblue;
      border-radius: 10px;
      background-color: $scblue;
      color:  #F7FBF4;
      font-weight: bold;
      


    }
  }
  
  input, select {
    width: 370px;
    margin-bottom: 0.25em;
  }

  label {
    text-align: right;
    line-height: 1.5em;
  }
  
  ul {
    list-style-type: none;
  }

  a:link {
    color: #000066;
  }

  a:visited {
    color: #000066;
  }

  a:hover {
    color: #000066;
    font-weight: 600;
    letter-spacing: -0.06em;
  }

  a:active {
    color: #000066;
  }
}

.panel {
  margin-top: 1.1em;
}

.nowrap {
    white-space: nowrap;
}

ul.emoji-list * {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    ul.emoji-list li {
      font-size: 1rem;
      float: left;
      display: inline-block;
      padding: 2px;
      margin: 4px;
    }
    img.emoji {
      cursor: pointer;
      height: 1rem;
      width: 1rem;
      margin: 0 .05em 0 .1em;
      vertical-align: -0.1em;
    }

#markdown{
  margin-top: 50px;
}

.notice {
  padding-top: 100px;
}

.product-header.zen {
	padding-top: 1.5em;
}

.zen_release {
	margin:2.0em;
}

//****************************************************************************
 
#scs_nav{
  position: fixed;
  top:0px;
  left:0px;
  padding-top:0px;
  padding-bottom:0px;
  margin: 0px;
  width: 100%;
  min-height: 90px;
  max-height: 105px;
  border: none;
  z-index:1;
 
  .top-bar{
    padding-top:0px;
    padding-bottom:0px;
    background-color: #fff;
  }

    .plugin-tag{
      position: relative;
      margin-top: -45px;
      margin-left: 130px;
      font-size: 15rem;
      text-align: left;

      h2{
        font-size: 1rem;
      }
    }
    
 

  .nav-logo {
    align: middle;
    margin-top: 0px;
    margin-bottom: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 325px;

    }

    @media screen and (min-width: 45rem) {
      position: relative;

      .top-bar{
        padding-top:0px;
        padding-bottom:0px;
        background-color: transparent;
        }


     .plugin-tag{
      position: relative;
      margin-top: -70px;
      margin-left: 195px;
      text-align: left;
      font-size: 1rem;

      h2{
        font-size: 1.55rem;
      }
      } 

    .nav-logo {
      width: 500px;
    }

    @media print {
       .top-bar{
        padding-top:0px;
        padding-bottom:0px;
        background-color: red;
        }


    }




  }
}

//****************************************************


//***************************************************

#scs_home{
  margin-top: -75px;
  min-height: 600px;
  width: 100%;
  text-align: left;


  .prod_header {
    margin-top: 200px;
    text-left: center;

    @media screen and (min-width: 45rem) {
      margin-top: 220px;
      }
    }

  }

  .promote {
    padding-top: 1.0em;
    padding-bottom: 0px;
    padding-left: 5.0em;
    padding-right: 5.0em;
    font-size: 1.1rem;
    text-align: center;
    p{
      text-weight: bold;
      color: $charcoal;
      font-size: 1.2rem;
    }
  }

    .learn-more{
      margin-left: 50%;
  
      @media screen and (min-width: 45rem) {
        margin-left: 40%;
      }
  }




  .prod_components{
    text-align: left;
    line-height: 1.5em;
    font-size: 1.2rem;
        h1{
      font-size: 2.5rem;
      text-align: center;
      }

    .main_image{
      img {
        width: 200px;
      }
     }

    .description{
    margin-top: -30px;
    }




    @media screen and (min-width: 45rem) {
      margin-top:50px;

        
        h1{
          text-align: left;
        }

      .main_image{
        margin-left: auto;
        margin-right: auto;
        img {
         float: right;

        }
      }

      .description{
    margin-top: -50px;
    }

    }
  }


//*************************************

.prod_icon {
      height: 300px; 
      width:300px;
      margin-top: -10px;
      margin-bottom: 40px;      
      opacity: 0.4;
}



.prod_icon.tlv{
      img{
        width: 250px;
        height:250px;
      }
}
.prod_buy.scs{
      position: relative;
        top: 4em;
        left:10.5em;
        width:120px;
        border:1px solid #005392;
        background: #005392;
        border-radius: 5px;
        text-align: center;
        text-shadow: 0.5px 0.5px ;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 4px;
        color: #FBD54F;
        font-weight: 900;
        font-size: 1.1rem; 
}

.prod_buy:hover{
    background-color: #5A6982;
    color:#FBD54F;
    border: solid 1px #5A6982;
}
    
.prod_download.scs,  .prod_download.tlv{
      position: relative;
      top: -70px;
      left: .5em;
      width:120px;
      border:5px solid #005392;
      background: #005392;
      border-radius: 5px;
      text-align: center;
      text-shadow: 0.5px 0.5px ;
      color: #FBD54F;
      font-weight: 900;
      font-size: 1.1rem;
      padding-left: 10px;
      padding-right: 10px;
}


.prod_download:hover{
    background-color: #5A6982;
    color:#FBD54F;
    border: solid 5px #5A6982;


  } 


@media screen and (min-width: 50rem){

      .prod_icon {

        height: 300px; 
        width:300px;
        float: right;
        margin-right: 5%;
        padding-right: 0;
        margin-bottom: 10px;
        opacity: 0.8;
  
            
      }

      .prod_icon.scs{
        margin-top: -12%;
        img{
           width: 300px;
           height:300px;
          }
      }


      .prod_icon.tlv{
         margin-top: -7.5%;
          img{
           width: 250px;
           height:250px;
          }

      }


      .prod_buy{
        position: relative;
          border: 1px solid #005392;
          background: #005392;
          border-radius: 5px;
          padding:5px;
          text-align: center;
          
          color: #FBD54F;
          font-weight: 900;
          font-size: 1rem;
      }

      .prod_buy.scs{
          top: 80px;
          padding-left: 20px;
          padding-right: 20px;
          left:200px;
          width:120px;
          margin-top: -100px; 
      }


       .prod_buy.tlv{
          left:150px;
          width:120px;
          margin-top: -100px; 
      }

      .prod_download {
        position: relative;
        width:120px;
        border:5px solid #005392;
        background: #005392;
        border-radius: 5px;
        text-align: center;

        
        color: #FBD54F;
        font-weight: 900;
        font-size: 1rem;
      }

       .prod_download.scs {
        top: -70px;
        left: 10px;
        padding-left: 10px;
        padding-right: 10px;
      }

      .prod_download.tlv {
        top: -60px;
        left: 0;
      }

}

.pricing{

      font-size: .8rem;
         a {
        color: #FBD54F;
        }

      a:hover {
      color: #FBD54F;
      }
      
      a:visited {
      color: #FBD54F;
      }
  

    h1{
      font-size: 2rem;
    }

      h2{
        color: #FBD54F;
      }

    .prod_buy_large{
        position: relative;
        width:300px;
        height: 70px;
        padding-top:20px;
        border:5px solid #005392;
        background: #005392;
        border-radius: 5px;
        text-align: center;
        vertical-align: middle;
       
        color: #FBD54F;
        font-size: 6rem;
        margin:auto;
        opacity: 0.8;

        

        }

     .prod_download_trial{
        position: relative;
        width:300px;
        height: 70px;
        padding-top:5px;
        border:5px solid #005392;
        background: #005392;
        border-radius: 5px;
        text-align: center;
        
        color: #FBD54F;
        font-size: 6rem;
        margin:auto;
        opacity: 0.8;

        }

}


//****************************************************************
.comp_features {

      margin-top: 0px;
      margin-bottom: 0px;
      padding-top:0px;
      padding-bottom: 0px;
      margin-right: auto;
      margin-left: auto;
      opacity: 0.7;
  }

.comp_title {
  position: relative;
    text-align: center;
}

.comp_title.mao{  
  h1{
    hyphens: none;
    -webkit-hyphens: none;
  }
}
    

    .comp_logo{
      position: relative;
      top: -10px;
      margin-left:auto;
      margin-right: auto;
      width:150px;
      padding: 20px 0 40px;
    }


    @media screen and (min-width: 50rem){



      .comp_features {
        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
        opacity: 0.7;

      }

       .comp_features.tlv { 
         max-width: 40%;
        }



      .comp_title {
        position: relative;
          padding-bottom: 20px;
          float:right;
          text-align: right;
          font-size: 1.3rem;
          font-weight: 900;


      }

        .comp_title.mt{
          }

          .comp_title.mao{
          }

          .comp_title.mp{
          }

          .comp_title.sp{
          }


      
      .comp_logo {
        position: relative;
        top: -70px;
        left: -10px;
        width:150px;
        height: 150px;
        text-align: center;
        margin: 40px -150px 10px;
        padding: 0px;
      }

    }

    //***********************************************

.version {
  margin-left: -20px;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 115%;
  font-size: .8rem;

  img{
    margin-left:auto;
    margin-right:auto;
  }

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    width: 11%;
  }

  td .wide, th .wide{
    width: 23%;
  }




  a {
    color: white; 
  }



  tr:nth-child(even){
    background-color:transparent;
  }

  .supported{
    
    td{
    padding-top:30px;
    padding-bottom: 30px;
    }
  }

   .unsupported {
    background-color: #485468;
    color: white;
    font-weight: 700;
    font-size: 1.1rem;
  }

  tr:hover {
    background-color:transparent;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #485468;
    color: white;
    text-align: center;
  }

}

.multi_color_ordered_list{
  clear: both;
  list-style: none;
  

  li{
    margin-top:1em;
    padding-top: .7em;
    padding-left: 3em;
    
    counter-increment: inst;
    display: block;
    position: relative;
  } 

  li:before {
    content: counter(inst);
    left: -10px;
    top:0;
    height: 1.9em;
    width: 1.9em;
    position: absolute;
    
    padding-top:.15em;
    padding-left: auto;
    padding-right: auto;
    padding-bottom: auto;

    border-radius:50%;

    background-color:#000;


    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.5em;
    font-weight: 700;
         
  }

  li:nth-child(1):before {
      background-color:#7732D2;  
  }

  li:nth-child(2):before {
      background-color:#e92619;  
  }
  li:nth-child(3):before {
      background-color:#118d14;  
  }
  li:nth-child(4):before {
      background-color:#0626aa;  
  }
  li:nth-child(5):before {
      background-color:#FD7225;  
  }
}

.download {
    width: 100%;
    padding:0;
    margin:0;
    line-height: 50px;
    background-color: #eb6e37;
    color: white;
    text-align: center;
    font-weight: bolder;
    border-radius: 5px;
  } 



  .download:hover {
  color: #485468;
  }

.circle{

    background-color:#000;
    
    display:inline-block;
    padding-left:18px;
    padding-right:18px;

    text-align:center;
    color:#fff;
    font-size: 1rem;

    border-radius:50%;
  }

  .mypurple,.mypurple:hover{
    background-color:#7732D2!important;
    
  }
  
  .myred,.myred:hover{
    background-color:#e92619!important;
  }

  .mygreen,.mygreen:hover{
    background-color:#118d14!important;
  }

  .myblue,.myblue:hover{
    background-color:#0626aa!important;
  }

  .myorange,.myorange:hover{
    background-color:#FD7225!important;
  }



  //*******************************
  
.sc_pricing_list li {
  margin-left: 2.5rem;
}

.sc_pricing{
  padding: 0;
  width:100%;
  margin: 0;

  .sc_price_outline_bl{
    border:none;
  }


  .sc_pricing_header{
    padding:10px;
    width:100%;
    text-align: center;
    font-size:1.4rem;
    color: $scblue;
  }

  .sc_purchase_price{
    padding:10px;
    width:100%;
    height: 2.1em;
    text-align: center;
    text-shadow: 0.5px 0.5px;
    font-weight:900;
    font-size:1.6rem;
    color: $orange1;
  }

  .sc_display_price{
    padding:10px;
    width:100%;
    height: 2.1em;
    text-align: center;
    text-shadow: 0.5px 0.5px;
    font-weight:900;
    font-size:1.6rem;
    color: $scblue;
  }

  .key_text {
    font-family: "Courier"
  }  

  .sc_pricing_content{
    padding: 40px;

    h2{
      font-size: 1.9rem;
    }

    .refund{
      font-size: 0.8em;
      color: $orange1;
      text-align: center;
        
        a {
          color: $orange1;
          font-weight:bolder;
        }
      
      a:hover{
        color: grey;
      }
    }
  }

  button{
    background-color: transparent;
    color:$scblue;
    font-size: 1.8rem;
    text-shadow: 0.5px 0.5px;
    line-height: 2.0;
    font-weight: 900;
    height: 100%;
    width:100%;

  }

  .btn_purchase {
    color:$scblue;
    font-size:1rem; 
    line-height: 1.1;   
    height: 100%;
    width:50%;
    margin-top:20px;
    margin-bottom:20px;
    margin-right:25%;
    margin-left:25%;
    padding:5px 15px;
    border: solid 2px $orange1;
    border-radius: 5px;
    position: relative;
    
    button{
      color:$scblue;
    }
    
  }

  .quantity_desc {
    font-size: 0.75em;
    font-family: "Maven Pro";
  }

  .btn_purchase.disable, .btn_purchase.disable:hover {
    color: $medium-gray;
    background-color: $light-gray;
    border: solid 1px $medium-gray;
  }
    
  .btn_purchase:hover {
    background-color: #FF8450;
    color:$scblue;
    border: solid 2px #FF8450;
  } 

  .btn_hidden {
    display: none;
  }

  .license{
  margin-top:10px;
  margin-left: 10px;
  height:100%;
  font-size: 1.4rem;
  line-height: 2.4;  

    input{
      margin:20px 0 0 0;
      padding:15px 10px;
      width:95%;
      border: solid 2px $scblue;
      border-radius:5px;
      font-size:1rem;
    }

    input:focus {
      border: solid 2px $scblue;
      background-color: transparent;
      outline: none;
      box-shadow:none;

    }

    .btn_renewal{
      margin:20px 0 0 0;
      padding:5px 15px;
      font-weight: bolder;
      font-size:1.1rem; 
      color:#fff;
      text-shadow: 0.5px 0.5px;
      background-color: $scblue;
      border: solid 1px $scblue;
      border-radius:5px;
      width:95%;
      
      button{
        color:#fff;
      }
      
    }
    
    .hidden {
      display: none;
    }
    
    #add_user_count {
      margin-bottom: -10px;
      
      p {
        margin: 0px;
      }
      
      #count_change {
        padding: 0px;
        padding-left: 0.25em;
        margin: 0px;
        display: inline-block;
        max-width: 70px;
        float: initial;
        text-align: center;
        border-color: $orange1;
      }
    }

    #action_choice {
      p {
        text-align: center;
        font-size: 0.8em;
        margin-top: 0px;
        margin-bottom: -10px;
      }
    }


    .btn_renewal:hover{
      background-color: #5A6982;
      color:#fff;
      border: solid 1px #5A6982;
    }
    
    #expiration_date {
      margin-bottom: 7px;
    }
	
  #coupon_info {
    font-size: 15px;
    font-weight: bold;
  }
  
	.renewal_details p {
		font-size: 1.0rem;
		line-height: 2.0;
		margin: 5.0px;
	}
  }

  .sc_renewal_price{
    margin-top:-1em;
    padding-bottom: 3em;
    width:100%;
    text-align: center;
    text-shadow: 0.5px 0.5px;
    font-weight:900;
    font-size:1.1rem;
    line-height:1.2;
    color: $orange1;
  }

  .btn_download {
    font-size: 1.6rem;
    line-height:1.8;
    font-weight: bolder;
    color:#FBD54F;
    text-shadow: 0.5px 0.5px;
    padding:10px 20px 10px 20px;
    background-color: #005392;
    border: solid 2px #005392;
    border-radius: 5px;
    opacity: 0.7;
  }

 
  
  .btn_download:hover{
    background-color: #5A6982;
    color:#FBD54F;
    border: solid 2px #5A6982;
  } 

  p {
    padding:0;
    margin-top:7px;
    border:none;
    line-height: 2.2;
    font-size: 0.9em;
    width:100%;
    color: $scblue;
  }

  a:hover{
    color:#FF8450;
    font-weight: bolder;
    }


  .license_info {
      padding:10px;
      text-align: center;
      border: solid 2px $scblue;
      border-radius: 5px;
      width:100%;
  }

  #qualify-result {
    color: $alert-color;
    text-shadow: 0.5px 0.5px;
    font-weight: bolder;
  }

  .alert {
    color: $alert-color;
  }
  .warn {
    color: $orange1;
  }
  .info {
    color: #54B9A0;
    font-weight:bolder;
    text-shadow: 0.5px 0.5px;

  }

  @media screen and (min-width: 45rem){
  font-size:1.5rem;
    
    .sc_price_outline_bl{
      border: solid 2px $scblue;
    }
    .sc_pricing_header{
      text-align: left;
      font-size:1.9rem;
      float:left;
    }
    .sc_purchase_price{
        font-size:2rem;
       }
     .sc_display_price{
        font-size:2rem;
       }  
    .btn_purchase{
      font-size:1.4rem; 
      line-height: 1.5;  
      padding: 15px 10px;
    }
    .license{
      input{
        width:66%;
        float:left;
        font-size:1.4rem;
      }
    
      .btn_renewal{
        width:33%;
        font-size:1.5rem;
        margin-left: 5px;
      }

      .sc_renewal_price{
    margin-top:0;
    padding-bottom: 2em;
      }
    }
  }
}

.section-header{
  margin: 100px 100px 50px 100px;

  h1{
    font-size: 3.5rem;
    font-weight: 600;
  }
  @media screen and (min-width: 45rem){
  margin: 60px 100px;
  }  

}


.zoom90 {
          zoom: 0.90; /* all browsers */
         -moz-transform: scale(0.90); /* Firefox */
}

.zoom80 {
          zoom: 0.80; /* all browsers */
         -moz-transform: scale(0.80); /* Firefox */
}


.zoom75 {
          zoom: 0.75; /* all browsers */
         -moz-transform: scale(0.75); /* Firefox */
}


.lds-ellipsis {
  display: inline-block;
  position: absolute;
  margin-top: -22px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $medium-gray;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

//**************************************************

  .ventura_content{
      opacity: 1.0;
      font-weight:300;
      line-height:30px;
      color: #000;
      margin-top: 20px;
      margin-right: 10%;
      margin-left: 10%;

      h2{
        color: #000;
      }
      
      .img {
        padding:25px; 
        width:150px;
      }

      a {
        color:$maven-color;
      }

      a:hover{
        color: grey;
      }

  }
  .bg_ventura {
  position: relative;

  /* extra style to center the content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg_ventura:before {
  content: "";
  background-image: url(https://media.smallcubed.com/notices/VenturaBackground.png);
  position: absolute;
  background-size: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: .6;
}

//*********************************
