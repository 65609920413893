
#admin {
	width: 90%;
	margin-top: 10em;
	margin-bottom: 3em;
	margin-right: 190px;
	margin-left: 4.0em;;

	.right-side {
		text-align: right;
	}
	select {
		width: 300px;
		clear: both;
	}
	p, td {
		color: $charcoal;
	}

	a {
		color: $charcoal;
		font-weight: bold;
	}
	a:hover {
		text-decoration: underline;
	}

	p.note {
		font-size: 0.75em;
		color: $warning-color;
	}

	p.error {
		font-size: 1.0em;
		color: $alert-color;
	}
	
	div.admin_form {
		p.note, p.error {
			margin-top: 0.75em;
			margin-bottom: 0;
		}
		input.full {
			width: 100%;
			margin-top:0.25em;
		}
	}
	
	tr.sub-active {
		td.status {
			color: green;
		}
	}
	
	tr.hidden {
		display: none;
	}

	td.live-yes {
		color: green;
	}
	td.live-no {
		color: red;
	}
	
	td.not-found {
		text-align: center;
	}

	tr.sub-inactive {
		td {
			color: darkGrey;
		}
		td.status {
			color: $alert-color;
		}
	}
	
	td button {
		background-color: smart-scale($medium-gray, -10%, 20%);
		color: white;
		border-color: smart-scale(darkGray);
		border-width: 1px;
		border-style: solid;
		border-radius: 2px;
		padding: 2px;
		padding-left: 3px;
		padding-right: 3px;
		margin-top: 0.25em;
		margin-left: -2.0em;
		font-size: 0.8em;
		float: right;
	}

	span.processed {
		float: right;
		color: green;
	}
	
	td button.copy-button {
		background-color: transparent;
		border-color: none;
		border-width: 0px;
		border-style: none;
	}
	
	td span.zero-width {
		display: none;
		width: 0px;
	}
}

#test_display {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	border: dotted 1px;
	padding: 1.0em;
	margin-bottom: 2.0em;
	font-size: 0.75em;
}

#info_display {

	margin-left: 1.0em;
	margin-bottom: 2.0em;

	.code_section {
		font-family: courier, fixed-width;
		font-size: 0.9em;
		margin-left: 2.0em;
	}

	hr {
		margin-left: 2.0em;
		width: 30%;
	}
}

div.admin_form {
	margin-bottom: 1.0em;
	margin-left: 2.0em;
	margin-right: 2.0em;

	.form_row {
		margin-bottom: 0.5em;
		label {
			max-width: 20%;
			text-align: right;
			display: inline-block;
		}
		input {
			width: 30%;
			text-align: left;
		}
	}
	
	.inline_row {
		margin-top: 0.5em;
		label {
			margin-right: 1.5em;
			text-align: left;
		}
	}

	.left_column, .right_column {
		width: 50%;
		display: inline-block;
	}
	
	button {
		background-color: smart-scale($medium-gray, -10%, 20%);
		color: white;
		border-color: smart-scale(darkGray);
		border-width: 2px;
		border-style: solid;
		border-radius: 4px;
		padding: 2px;
		padding-left: 3px;
		padding-right: 3px;
		margin-top: 0.5em;
		margin-left: 0px;
	}

	button.admin_inline {
		margin-left: 10px;
	}

}

div.columns {
	.admin_form {
		.form_row {
			label {
				width: 30%;
				text-align: right;
				display: inline-block;
			}
			label.compact {
				max-width: 15%;
			}
			input {
				width: 65%;
				text-align: left;
			}
		}
	}
	.compact {
		margin-left: 0px;
	}
}

.plan_date {
	min-width: 110px;
}


div.login {
	min-height: 480px;
	margin-top: 10em;
}

div.admin_profile {
	margin-top: 1.0em;
	margin-right: 75px;
	padding-top: 1em;
	padding-bottom: 1em;
	padding-left: 2em;
	border: 1px solid;
	border-radius: 5px;

	p {
		font-weight: bold;
	}

	.form_row {
		input {
			width: 50%;
		}
	}

}

.admin_actions {
	padding-left: 1.0em;
}


.results {
	margin-top: 1.5em;

	table {
		font-size: 0.9em;
	}
}

.fixed_width {
	font-family: courier, fixed-width;
	font-size: 0.8em;
	color: $charcoal;
}

.codes {
	margin-bottom: 1.25em;
}

#admin_sidebar {
	position: fixed;
	top: 112px;
	right: 11px;
	background-color: darkGray;
	border-radius: 10px;
	border: 2px solid;
	border-color: $dark-gray;
	padding: 0.5em;

	ul {
		list-style-type: none;
	}

	p, li, a {
		color: $white;
	}
}

